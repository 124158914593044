<template>
    <div class="content_wpr">
        <div class="section_content w-100">
            <div class="acton_header">
                <h2>Single Purchases</h2>
                <div class="client_info">
                    <div>
                        <h5>{{ selectedClient.full_name }}</h5>
                        <h6>{{ selectedClient.email }}</h6>
                    </div>
                    <img :src="selectedClient.profile_pic ? selectedClient.profile_pic : require('@/assets/images/male-female.png')" alt="">
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions mt-4">
                    <ul>
                        <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search..." @input="isTyping = true" :disabled="purchaseProductsLoader" v-model.trim="params.search"/>
                            <button class="search_btn" @click="searchField = !searchField;">
                            <i class="fas fa-search"></i>
                            </button>
                        </li>
                        <li class="break_line"></li>
                    </ul>
                </div>
                <div class="contact_loader" v-if="purchaseProductsLoader">
                    <quote-loader />
                </div>
                <table class="show_header" v-show="!purchaseProductsLoader">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Products Name</th>
                            <th>Total</th>
                            <th><label>Product Status</label></th>
                            <!-- <th><label>Bump Status</label></th> -->
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="purchaseProducts.total">
                        <tr v-for="(order, o) in purchaseProducts.data" :key="o">
                            <td><a @click="handleOrderDetails(order)">{{ order.order_id }}</a></td>
                            <td>{{ moment(order.created_at).format('ll | LT') }}</td>
                            <td>
                                <div class="user_wpr">
                                    <h4>{{ selectedClient.full_name }}</h4>
                                </div>
                            </td>
                            <td v-if="order.has_bump">{{ order.bump ? order.bump.name : '-' }}</td>
                            <td v-else>{{ order.product ? order.product.name : '-' }}</td>
                            <td>{{ companyCurrencySymbol }}{{ order.has_bump ? parseFloat(order.bump_price).toFixed(2) : parseFloat(order.price).toFixed(2) }}</td>
                            <td><label>{{ order.has_bump ? order.bump_status : order.status }}</label></td>
                            <!-- <td><label>{{ order.has_bump ? 'Bump' : 'No Bump' }}</label></td> -->
                            <td>
                                <ul class="action_list">
                                    <li v-if="companyUserCan('create', 'checkouts')" title="Refund" @click="handleRefund(order)"><i class="fas fa-reply"></i></li>
                                    <li title="Send Invoice" @click="sendOrderInvoice(order.order_id)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != order.order_id, 'fa fa-spin fa-spinner': sendInvoiceLoader == order.order_id }"></i></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="px-4 text-center">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_footer">
                <ul>
                    <li>{{ purchaseProducts.from ? purchaseProducts.from : 0 }} - {{ purchaseProducts.to ? purchaseProducts.to : 0 }} of {{ purchaseProducts.total }}</li>
                </ul>
            </div>
            <div class="pagination" v-show="purchaseProducts.total">
                <pagination v-model="params.page" :pages="purchaseProducts.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
    </div>
    <order-details v-model="orderDetails" :selected-client="selectedClient" :selected-order="selectedOrder" />
    <refund-component v-model="refundPayment" :refund-amount="refundAmount" :order-id="refundOrderId" :refresh-records="handleRefreshRecords" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    import Helper from '@/utils/Helper'
    import moment from 'moment'
    import Swal from 'sweetalert2'

    const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))
    const OrderDetails = defineAsyncComponent(() => import('@/pages/checkout/components/client/OrderDetails'))

    export default {
        name: 'Client Single Purchase',

        data () {
            return {
                refundPayment: false,
                orderDetails: false,
                params: {
                    client_id: '',
                    page: 1,
                    per_page: 10,
                    search: '',
                    order_type: 'product'
                },
                isTyping: false,
                moment,
                selectedOrder: {},
                refundAmount: 0,
                refundOrderId: 0,
                searchField: false,
            }
        },

        props: {
            selectedClient: {
                type: Object,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        watch: {
            'params.search' (val) {
                const vm = this;
                vm.params.search = val;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getSinglePurchaseProducts(vm.params);
                        }
                        if (vm.params.search.length == 0) {
                            vm.params.page = 1;
                            vm.getSinglePurchaseProducts(vm.params);
                        }
                    }
                }
            },
        },

        components:{
            RefundComponent,
            OrderDetails,
        },

        computed: {
            ...mapState({
                purchaseProductsLoader: state => state.checkoutModule.singlePurchaseProductsLoader,
                purchaseProducts: state => state.checkoutModule.singlePurchaseProducts,
                companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
                sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm(vm.params);
        },

        methods:{
            ...mapActions({
                getSinglePurchaseProducts: 'checkoutModule/getSinglePurchaseProducts',
                sendOrderInvoice: 'checkoutModule/sendOrderInvoice',
            }),

            resetForm () {
                const vm = this;

                vm.params.client_id = vm.selectedClient.id;
                vm.getSinglePurchaseProducts(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSinglePurchaseProducts(vm.params);
            },

            handleOrderDetails (order) {
                const vm = this;

                vm.selectedOrder  = order;
                vm.orderDetails   = true;
            },

            handleRefund (order) {
                const vm = this;

                if (order.refund_amount || order.bump_refund_amount) {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already been refunded, you can not refund it again.`, 'OK'));
                } else {
                    const refundAmount  = order.has_bump ? order.bump_price : order.price;

                    vm.refundAmount     = parseFloat(refundAmount).toFixed(2);
                    vm.refundOrderId    = order.order_id;
                    vm.refundPayment    = true;
                }
            },

            handleRefreshRecords () {
                const vm = this;

                vm.getSinglePurchaseProducts(vm.params);
            }
        }
    }
</script>

<style scoped>
    .form_grp h4 {
        font-size: 15px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
        display: flex;
    }
    .form_grp h4 .btn {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        background: #2f7eed;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: auto;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table td a{
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }

    .result_wpr table td .action_list{
        display: flex;
        gap: 10px;
    }

    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }

    .result_wpr table td label {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #9FB1CF;
        border-radius: 11px;
        padding: 3px 10px;
    }

    .form_grp input.no_spin::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }

    .form_grp input[type=number].no_spin {
        -moz-appearance: textfield;
    }
</style>
